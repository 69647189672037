import React from "react";
import "./about.css";
import { Container, Col, Image, Row } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import { CardComp } from "../../components";
import { aboutPageData, docLink } from "../../helper/helper";
import useMoveToTop from "../../helper/moveToTop";

export default function About() {
  useMoveToTop();

  console.log("secret", process.env.REACT_APP_SECRET_KEY);
  return (
    <div className="about">
      <div className="titleContainer">
        <h4 className="title">About Us</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.about1} thumbnail className="img"></Image>
        </Col>
        <Col>
          <h5 style={{ textAlign: "justify" }}>
            In the year 1985 school has been stared as Kinder Garden School and
            later on classes has been promoted up to the level of 10th class. In
            the year 1992 Arya Adarsh High School Akhara Bazar Kullu has been
            run under the guidance of Sh Satya Pal Bhatnagar Retd. Principal
            from HP Government .In the year 1993 for smooth functioning of
            school a Society has been formed in the name of Arya Adarsh Shikshan
            Sansthan Kullu. Now school is being managed by Arya Adarsh Shikshan
            Sansthan which is registered under Registration Act, 1860 bearing
            Registration No.117. <br />
            <br /> The school is situated in the premises of Arya Samaj Kullu,
            on a land piece of 16 Biswas,. The school is ideally located on the
            road side of state high way. The campus offers easy connectivity,
            access to the state-of-art and best facility to the students. Arya
            Adarsh High School provides academic excellence and extra
            co-curricular activities. The school is affiliated with Hp Board of
            School Education Dharamshala for 9th & 10th Class (Affiliation No.
            19501) and also recognized by Directorate of Elementary Education,
            Kullu (H.P.)
          </h5>
          <br />
          <h5 style={{ display: "flex" }}>
            <span className="primaryTextColor">
              Management Committee Member List:{" "}
            </span>
            <p
              style={{
                color: "var(--menuHoverColor)",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() => window.open(docLink.mCommitee)}
            >
              {" "}
              View
            </p>
          </h5>
        </Col>
        <br />
        <br />
        <Col>
          <Row xs={1} md={2} className="g-4">
            {aboutPageData.map((item, index) => {
              return <CardComp key={index} item={item} />;
            })}
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
}
