import React from "react";
import "./missionVision.css";
import { Container, Col, Image, Row } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import { visionPageData } from "../../helper/helper";
import { CardComp } from "../../components";
import useMoveToTop from "../../helper/moveToTop";

export default function MissionVision() {
  useMoveToTop();

  return (
    <div className="about">
      <div className="titleContainer">
        <h4 className="title">Vision and Mission</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.vision} thumbnail className="img"></Image>
        </Col>
        <Col>
          <h4 className="title">Vision</h4>
          <h5 style={{ textAlign: "justify", marginBottom: "20px" }}>
            Arya Adarsh High School Akhara Bazar Kullu will Respect self, others
            and environment. We lay emphasis on an education that is
            participatory in nature, intellectually competent, and use
            co-operative and independent learning styles multi-skill oriented,
            value based and socially committed and aims to develop in children
            the knowledge and skills to enable them achieve their career goals
            and become caring and open minded. To provide a teaching and
            learning environment this is enriched with purposeful and deliberate
            experiences to extract the best from our students.
          </h5>
        </Col>
        <Col>
          <h4 className="title">Mission</h4>
          <h5 style={{ textAlign: "justify" }}>
            Arya Adarsh Shikshan Sansthan’s mission is to guide students so that
            they can become good citizen and contribute positive environment in
            the society. Arya Adarsh High School will inspire students to
            respect themselves & others, appreciate the value of diversity &
            individual creativity, seek a set of moral standards, which may help
            the students for lifelong.
          </h5>
        </Col>
        <Col>
          <br />
          <br />
          <Row xs={1} md={2} className="g-4">
            {visionPageData.map((item, index) => {
              return <CardComp key={index} item={item} />;
            })}
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
}
