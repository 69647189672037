import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { docData } from "../../helper/helper";
import imagesData from "../../helper/imagesData";
import useMoveToTop from "../../helper/moveToTop";
import "./mandatory.css";

export default function Mandatory() {
  useMoveToTop();
  return (
    <div className="faculty">
      <div className="titleContainer">
        <h4 className="title">Mandatory Documents</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image
            src={imagesData.mandatoryDoc}
            thumbnail
            className="img"
          ></Image>
        </Col>
        <br />
        <Col>
          <Row
            xs={1}
            md={3}
            className="g-4"
            style={{ justifyContent: "space-evenly" }}
          >
            {docData.map((item) => (
              <Card
                bg={"light"}
                key={item.id}
                text={"black"}
                style={{ width: "18rem" }}
                className="mb-2"
              >
                <Card.Header className="cardHeader">{item.name}</Card.Header>
                <Card.Img src={imagesData.doc} className="cardImage" />

                <Card.Text style={{ alignSelf: "center", cursor: "default" }}>
                  <Card.Link onClick={() => window.open(item.link)}>
                    Click to view
                  </Card.Link>
                </Card.Text>
              </Card>
            ))}
          </Row>
        </Col>
        <br />
        <Col></Col>
      </Container>
    </div>
  );
}
