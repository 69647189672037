import React from "react";
// import { Link } from "react-router-dom";
import "./home.css";
import { CarouselComp, CardComp } from "../../components";
import { Col, Image, Row } from "react-bootstrap";
import { galleryImages, homePageData } from "../../helper/helper";
import AchievementCara from "../../components/carousel/AchievementCara";
import useMoveToTop from "../../helper/moveToTop";
import BannerModal from "../../components/bannerPopup/BannerModal";
import { useNavigate } from "react-router-dom";

export default function Home({ setModalShow, modalShow }) {
  const navigate = useNavigate();

  useMoveToTop();

  const closeAndNavigate = () => {
    setModalShow(false);
    navigate("/admission");
  };

  return (
    <div className="home">
      <CarouselComp />
      <Col className="cardContainer">
        <Row xs={1} md={2} className="g-4">
          {homePageData.map((item, index) => {
            return <CardComp key={index} item={item} />;
          })}
        </Row>
      </Col>
      <Row style={{ overflowX: "hidden" }}>
        <AchievementCara />
      </Row>
      <Col className="imgColumn">
        <Row></Row>
        <Row xs={1} md={4} lg={6} style={{ justifyContent: "center" }}>
          {galleryImages.map((img, index) => (
            <Col className="imageCon" key={index}>
              <Image src={img} thumbnail className="imgC" />
            </Col>
          ))}
        </Row>
      </Col>
      <br />
      <br />
      <BannerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        closeAndNavigate={closeAndNavigate}
      />
    </div>
  );
}
