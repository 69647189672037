import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import {
  Home,
  About,
  Contact,
  Admission,
  Facilities,
  MissionVision,
  PrincipalMessage,
  Faculty,
  Mandatory,
} from "./pages";
import { Header, Footer } from "./components";
import React from "react";

function App() {
  const [modalShow, setModalShow] = React.useState(true);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={<Home modalShow={modalShow} setModalShow={setModalShow} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/admission" element={<Admission />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mission" element={<MissionVision />} />
        <Route path="/principalmessage" element={<PrincipalMessage />} />
        <Route path="/faculty" element={<Faculty />} />
        <Route path="/mandatory" element={<Mandatory />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
