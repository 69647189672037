import React from "react";
import "./facilities.css";
import { Container, Col, Image } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import FacilityCard from "../../components/facilityCard/FacilityCard";
import { facilitiesData } from "../../helper/helper";
import useMoveToTop from "../../helper/moveToTop";

export default function Facilities() {
  useMoveToTop();

  return (
    <div className="facility">
      <div className="titleContainer">
        <h4 className="title">Facilities</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.facilities} thumbnail className="img"></Image>
        </Col>
        <Col>
          <h5 className="paragraphDetail">
            Arya Adarsh's teaching facilities are no less than those offered by
            many top schools of India and abroad.
          </h5>
        </Col>
        <br />

        {facilitiesData.map((item, index) => {
          return (
            <FacilityCard
              key={index}
              title={item.title}
              image={item.image}
              index={index}
              desc={item.desc}
            />
          );
        })}
      </Container>
    </div>
  );
}
