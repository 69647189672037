import { ToastContainer, toast } from "react-toastify";
import imagesData from "./imagesData";

//Content used for Card
const about = {
  header: "About Our School",
  desc: "In the year 1985 school has been stared as Kinder Garden School and later on classes has been promoted up to the level of 10th class.",
  link: "/about",
  img: imagesData.about1,
};

const principal = {
  header: "Principal's Message",
  desc: "Welcome to the website of Arya Adarsh High School Akhara Bazar Kullu As you navigate your way through the site discovering what we have to offer",
  link: "/principalmessage",
  img: imagesData.principal,
};

const vision = {
  header: "Vision & Mission",
  desc: "Arya Adarsh Shikshan Sansthan’s mission is to guide students so that they can become good citizen   and contribute positive environment in the society.",
  link: "/mission",
  img: imagesData.vision,
};

export const homePageData = [about, principal];
export const aboutPageData = [principal, vision];
export const visionPageData = [about, principal];
export const principalPageData = [about, vision];

//Toast Success method
export const toastSuccess = (Message) => {
  return toast.success(`Thanks!! ${Message}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastFailed = () => {
  return toast.error("Oops! Some thing went wrong. Please try again", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

//Facilities card data

export const facilitiesData = [
  {
    title: "Campus",
    image: imagesData.facilities1,
    desc: "School has well built separate blocks to house each department. There are spacious and well-ventilated classrooms, staff rooms and other amenities.",
  },
  {
    title: "Pupil-Teacher Ratio",
    image: imagesData.facilities2,
    desc: "School take care of PTR ratio. Smaller class sizes provide the opportunity for personal attention and additional instructional help when necessary",
  },
  {
    title: "Library",
    image: imagesData.facilities5,
    desc: "The School library is well stocked with all type of study books and various kinds of reference books, Numbers of magazines are available in library to keep the students updated with latest developments in the related field. Students can borrow books from the library for their study.",
  },
  {
    title: "A well qualifed Teachers",
    image: imagesData.facilities3,
    desc: "Teachers are important role models for their students. They can inspire and motivate their students to achieve great things. A good and well-qualified teacher can make a big difference in a child’s life",
  },
  {
    title: "Sports/Yoga",
    image: imagesData.facilities6,
    desc: "Students are encouraged to engage in physical education and for the overall development of personality. Indoor sporting facilities are available. An annual Sports day will be conducted and prizes are awarded to teams and individuals who prove their mettle.",
  },
  {
    title: "Computer Labs",
    image: imagesData.facilities4,
    desc: "The School has enough computer laboratories with requisite software for overall development of students.",
  },
];

//Home Carousel data

export const carouselData = [
  {
    title: "हमारे संस्कार हमारी पहचान है |",
    image: imagesData.home1,
    alt: "Arya Adarsh high school ",
  },
  {
    title: "Known for Quality Education",
    image: imagesData.home2,
    alt: "Arya Adarsh high school ",
  },
  {
    title: "We are known for our Disciplined Students",
    image: imagesData.home3,
    alt: "Arya Adarsh high school, kullu",
  },
];

//Gallery Images List

export const galleryImages = [
  imagesData.gallery1,
  imagesData.gallery2,
  imagesData.gallery3,
  imagesData.gallery4,
  imagesData.gallery5,
  imagesData.gallery6,
  imagesData.gallery7,
  imagesData.gallery8,
  imagesData.gallery9,
  imagesData.gallery10,
  imagesData.gallery11,
  imagesData.gallery12,
];

//Sending SMTP js mail helper function

export const smtpJs = (sub, body) => {
  return window.Email.send({
    SecureToken: process.env.REACT_APP_SECURE_TOKEN,
    To: "aryaadarshkullu@gmail.com",
    From: "saurabhkumar679@gmail.com",
    Subject: sub,
    Body: body,
  });
};

export const ToastContainerO = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};

export const facultyData = [
  {
    sr: "01",
    name: "Smt. Suman Sood",
    desDual: "M.A., B.Ed ",
  },
  {
    sr: "02",
    name: "Smt. Savita Thakur",
    desDual: "Shastri,+2, D.El.Ed ",
  },
  {
    sr: "03",
    name: "Smt. Surbhi",
    desDual: "B.A., B.Ed,",
  },
  {
    sr: "04",
    name: "Smt. Sunita",
    desDual: "B.A., Dip. in Art & Crafts ",
  },
  {
    sr: "05",
    name: "k. Kamla Devi ",
    desDual: "B.Sc, B.Ed ( Non- Medical)",
  },
  {
    sr: "06",
    name: "K. Meera",
    desDual: "B.Sc, B.Ed, MSc.",
  },
  {
    sr: "07",
    name: "Sh. Rajesh Kaith",
    desDual: "B.A., B.P.Ed",
  },
  {
    sr: "08",
    name: "Smt. Nirmla",
    desDual: "B.A.,B.Ed, M.A. (Hindi)",
  },
  {
    sr: "09",
    name: "Sh. Sanpat",
    desDual: "B.A., MSc (IT)",
  },
  {
    sr: "10",
    name: "K. Mamta",
    desDual: "B.A., D.El.Ed",
  },
  {
    sr: "11",
    name: "Smt. Veena Devi",
    desDual: "M.A, J.B.T",
  },
  {
    sr: "12",
    name: "Smt. Lalita",
    desDual: "B.A., B.Ed, M.A.",
  },
  {
    sr: "13",
    name: "Smt. Sunita Verma ",
    desDual: "B.A. ,N.T.T.",
  },
  {
    sr: "14",
    name: "Smt. Yogmaya",
    desDual: "+2 With Prabha kar",
  },
  {
    sr: "15",
    name: "K. Priyanka",
    desDual: "B.A. , J.B.T",
  },
  {
    sr: "16",
    name: "Smt. Chetna",
    desDual: "B.A., Diploma in Art & Crafts",
  },
  {
    sr: "17",
    name: "Smt. Nirmal",
    desDual: "B.Ed ",
  },
  {
    sr: "18",
    name: "Smt. Taramani",
    desDual: "Sub-Staff",
  },
  {
    sr: "19",
    name: "Smt. Indu",
    desDual: "Sub-Staff",
  },
  {
    sr: "20",
    name: "Smt Shivani Thakur",
    desDual: "Sub-Staff",
  },
];

export const docLink = {
  NOC: "https://drive.google.com/file/d/18o4ZVAhqBLkj0fZXhZTwpaa41n_EOSAW/view?usp=sharing",
  mCommitee:
    "https://drive.google.com/file/d/1pGQHymU1haj46at0PApEd4uEpSE9JnSp/view?usp=sharing",
  fireSafetyCert:
    "https://drive.google.com/file/d/12qir_XtXy_C-mFUa1xxjlmOO7eI4kHX-/view?usp=sharing",
  feeDetails:
    "https://drive.google.com/file/d/1zYzfarU5IgZD8TgINiHZIcHY96VGqWcG/view?usp=sharing",
  faculty:
    "https://drive.google.com/file/d/1nogo4utfmOBiJcbfQE_rJosj2D9APqdm/view?usp=sharing",
  buildingCert:
    "https://drive.google.com/file/d/1EuV6h7oOM3HKD8PO-6K_TNoeK3CSR8c1/view?usp=sharing",
  affilation:
    "https://drive.google.com/file/d/1f8evp_5tvbIYOOwOw4CwXQ0IV_9CAkOt/view?usp=sharing",
};

export const docData = [
  {
    id: 1,
    name: "NOC Certificate",
    link: docLink.NOC,
  },
  {
    id: 2,
    name: "Commitee Member Details",
    link: docLink.mCommitee,
  },
  {
    id: 3,
    name: "Fire Safety",
    link: docLink.fireSafetyCert,
  },
  {
    id: 4,
    name: "Faculty Details",
    link: docLink.faculty,
  },
  {
    id: 5,
    name: "Building Certificate",
    link: docLink.buildingCert,
  },
  {
    id: 6,
    name: "Affilation",
    link: docLink.affilation,
  },
  {
    id: 7,
    name: "Fee Details",
    link: docLink.feeDetails,
  },
];
