import React from "react";
import { Nav, Navbar, Col, Image } from "react-bootstrap";
import { FaTwitter, FaInstagram, FaFacebook, FaInbox } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
  return (
    <Navbar className="footer">
      <Col className="firstCol">
        <Nav>
          <div className="footerNav">
            <p className="contactHeader">Contact us ↓</p>
            <Image src="contactSVG.svg" className="contactImg" />
            <div>
              <p className="schoolName">Arya Adarsh High School</p>
              <p className="contactHeader">Akhara Bazar, Kullu</p>
              <p className="contactHeader">Himachal Pradesh- 175101</p>
              <p className="contactHeader">
                Phone : 01894-222952, 76510 02952{" "}
              </p>
              <p className="contactHeader">
                E-mail : aryaadarshkullu@gmail.com
              </p>
            </div>
          </div>
        </Nav>
      </Col>
      <Col className="otherCol">
        <Nav>
          <div className="footerNav">
            <p className="contactHeader">Quick Links ↓</p>

            <Link className="footerCard" to="/admission">
              <p>Admission Enquiry</p>
            </Link>
            <Link className="footerCard" to="/contact">
              <p>Contact Us</p>
            </Link>
            {/* <Link className="footerCard" to="/about">
              <p> About Us</p>
            </Link> */}
            <Link className="footerCard" to="/principalmessage">
              <p>Principal's Message</p>
            </Link>
            {/* <Link className="footerCard" to="/mission">
                <p>Vision & Mission</p>
              </Link> */}
            <Link className="footerCard" to="/facilities">
              <p>Facilities</p>
            </Link>
          </div>
        </Nav>
      </Col>
      <Col className="otherCol">
        <Nav>
          <div>
            <p className="contactHeader">Follow us ↓</p>
            <div className="socialLinks">
              <Nav.Link href="">
                <FaFacebook className="socialIcons" size={20} color="white" />
              </Nav.Link>
              <Nav.Link href="">
                <FaInstagram className="socialIcons" size={20} color="white" />
              </Nav.Link>
              <Nav.Link href="">
                <FaTwitter className="socialIcons" size={20} color="white" />
              </Nav.Link>
              <Nav.Link href="window.open('mailto:aryaadarshkullu@gmail.com')">
                <FaInbox className="socialIcons" size={20} color="white" />
              </Nav.Link>
            </div>
            <br />
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Location"
                  className="gmap_iframe"
                  width={"100%"}
                  frameBorder={0}
                  // scrolling="yes"
                  marginHeight={0}
                  marginWidth={0}
                  src="https://maps.google.com/maps?width=500&amp;height=400&amp;hl=en&amp;q=arya adarsh high school  kullu&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </Nav>
      </Col>
    </Navbar>
  );
}
